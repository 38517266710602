<template>
<Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
    <div class="height-div">
        <transition name="slide-fade">
            <div id="viajes" class="pt-16 pb-16" v-if="animation1">

            <p class="title-2 text-center">¡Viaja con Yipi!</p>
            <section class="flex-container flex-row-reverse offset">
            
                    <article class="flex-item center-flex">

                                   <video class="img-small" src="../assets/animacion/05/Yipi_AnimacionWeb_05.mp4" autoplay loop muted alt="animation yipi"/>
                      
                            
                        <li>         
                            <router-link :to="{ name: 'app'}" target="_blank">
                                    <img   class="btn mt-20" src="../assets/768x1024/Boton_App.png"/>
                            </router-link>
                        </li>

                    </article>

                    <article class="flex-item center-flex separator-space">
                  
                       
                         <video class="img-small" src="../assets/animacion/06/Yipi_AnimacionWeb_06.mp4" autoplay loop muted alt="animation yipi"/>
                        <li>         
                            <router-link :to="{ name: 'tarjeta'}" target="_blank">
                                <img  class="btn  mt-20" src="../assets/768x1024/Boton_Tarjeta.png"/>
                            </router-link>
                         </li>
                        
                    </article>

                </section>
            </div>
        </transition>
    </div>
</Intersect>
</template>
<style >
 @import '../styles/viaja.css';

</style>
<script>
  import Intersect from 'vue-intersect'
export default {
    components: { Intersect },
    data : () => ({
    animation1:false
    })
}
</script>